import React from 'react'
import { Layout } from '../components/layouts/signup'

export default function NotFoundPage(props) {
  return (
    <Layout>
      <div className="signup-step flex-center">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-9 col-lg-6">
            <h1 className="m-b-4">Sorry</h1>

            <p>
              You have used an incorrect link. Use a correct link or{' '}
              <a href="mailto:support@heylink.com">contact us</a>.
            </p>

            <div className="m-t-6 text-right">
              <a href="https://heylink.com" className="btn btn-primary">
                Go back to Heylink.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
